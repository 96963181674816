import makeFAIcon from "utils/makeFAIcon";

import {
  faPhone,
  faEnvelope,
  faPlus,
  faBars,
  faTimes,
  faGlobe,
  faMapMarker,
  faBed,
  faUserMd,
  faGavel,
  faRunning,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebookF,
  faLinkedinIn,
  faGithubAlt,
  faMediumM,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

// https://fontawesome.com/v5/search?o=r&m=free&s=solid
export const PhoneIcon = makeFAIcon(faPhone);
export const EnvelopIcon = makeFAIcon(faEnvelope);
export const PlusIcon = makeFAIcon(faPlus);
export const BarsIcon = makeFAIcon(faBars);
export const GithubIcon = makeFAIcon(faGithubAlt);
export const MediumIcon = makeFAIcon(faMediumM);
export const CloseIcon = makeFAIcon(faTimes);
export const LanguageIcon = makeFAIcon(faGlobe);
export const MapMarkerIcon = makeFAIcon(faMapMarker);
export const BedIcon = makeFAIcon(faBed);
export const MedicianIcon = makeFAIcon(faUserMd);
export const GavelIcon = makeFAIcon(faGavel);
export const RunningIcon = makeFAIcon(faRunning);

export const TwitterIcon = makeFAIcon(faTwitter);
export const FacebookIcon = makeFAIcon(faFacebookF);
export const InstagramIcon = makeFAIcon(faInstagram);
export const LinkedinIcon = makeFAIcon(faLinkedinIn);

export * from "config/CustomIcons";
