import React from "react";
import PropTypes from "prop-types";
import * as SocialIcons from "components/SocialIcons";

const Socials = ({ instagram, facebook, github, linkedin, medium, twitter }) => {
  return (
    <>
      {twitter ? <SocialIcons.Twitter userName={twitter} /> : null}
      {instagram ? <SocialIcons.Instagram userName={instagram} /> : null}
      {facebook ? <SocialIcons.Facebook userName={facebook} /> : null}
      {linkedin ? <SocialIcons.Linkedin userName={linkedin} /> : null}
      {github ? <SocialIcons.Github userName={github} /> : null}
      {medium ? <SocialIcons.Medium userName={medium} /> : null}
    </>
  );
};

Socials.propTypes = {
  instagram: PropTypes.string,
  facebook: PropTypes.string,
  github: PropTypes.string,
  linkedin: PropTypes.string,
  medium: PropTypes.string,
  twitter: PropTypes.string,
};

Socials.defaultProps = {
  instagram: null,
  facebook: null,
  github: null,
  linkedin: null,
  medium: null,
  twitter: null,
};

export default Socials;
