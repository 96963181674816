import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Collapse, Button } from "react-bootstrap";
import "./SectionHero.scss";
import Image from "./Image";

const SectionHero = ({ header, headerTitle, subheader, subheaderExtended, imageFileName }) => {
  const [open, setOpen] = useState(false);

  return (
    <Row className="container section-hero d-flex">
      <Col className="col-lg-6 col-12 offset-lg-1 p-lg-0 mt-3 mt-lg-0 pr-lg-5">
        <div className="text-lg-start text-sm-center">
          <span className="text-uppercase h6 font-weight-semibold">{header}</span>
          <h2 className="h2 mb-4">
            <span className="hero-title">{headerTitle}</span>
          </h2>
          <div className="hero-body" dangerouslySetInnerHTML={{ __html: subheader }} />
          {subheaderExtended ? (
            <>
              <Collapse in={open}>
                <div
                  className="hero-body"
                  dangerouslySetInnerHTML={{ __html: subheaderExtended }}
                />
              </Collapse>
              <Button
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className="mt-3"
              >
                Leggi {open ? "di meno" : "di più"}
              </Button>
            </>
          ) : null}
        </div>
      </Col>
      <Col className="col-lg-5 col-12 d-flex mt-5 mt-lg-0 justify-content-end">
        <Image className="image " fileName={imageFileName} alt={header || subheader} />
      </Col>
    </Row>
  );
};

SectionHero.propTypes = {
  header: PropTypes.string,
  headerTitle: PropTypes.string,
  subheader: PropTypes.string,
  subheaderExtended: PropTypes.string,
  // className: PropTypes.string,
  imageFileName: PropTypes.string,
};

SectionHero.defaultProps = {
  header: "",
  headerTitle: "",
  subheader: "",
  subheaderExtended: "",
  // className: null,
  imageFileName: null,
};

export default SectionHero;
